import React from "react"
import { useHistory } from "react-router-dom"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const history = useHistory()

  return (
    <div className="navbar-component">
      <div className="back-button" onClick={() => history.goBack()}>
        <img src={IMAGE.back} alt="" />
      </div>
    </div>
  )
}

export default IndexComponent
import React from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()

  return (
    <div className="download-page">
      <h1 className="title">{ t("download.title") }</h1>
      <h2 className="content">{ t("download.content") }</h2>
      <img src={IMAGE.appStore} alt="" className="app-store-button" onClick={() => window.open("https://apps.apple.com/us/app/futurescash/id1578643022")} />
      <img src={IMAGE.googlePlay} alt="" className="google-play-button" onClick={() => window.open("https://play.google.com/store/apps/details?id=cash.futures.app")} />
      <div className="apk-button" onClick={() => window.open("https://support.futures.cash/hc/en-us/articles/19536913732377-FuturesCash-APK-download")}>
        <h1>{ t("download.apk") }</h1>
      </div>
    </div>
  )
}
export default withRouter(IndexPage)
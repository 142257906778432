import React, { createContext, useReducer, useContext } from "react"
import reducer from "./reducer"

export const initState = {
  inviter: sessionStorage.getItem("inviter") || '',
  email: sessionStorage.getItem("email") || '',
  code: sessionStorage.getItem("code") || '',
  status: sessionStorage.getItem("status") || '',
  loginStep: sessionStorage.getItem("loginStep") || '',
}

const ConfigCtx = createContext(null)

export const Provider = props => {
  const [state, dispatch] = useReducer(reducer, initState)
  return <ConfigCtx.Provider value={{ state, dispatch }}>{ props.children }</ConfigCtx.Provider>
}

export const useConfigStore = () => useContext(ConfigCtx)
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import { Kform1 } from "@/component"
import { useConfigStore } from "@/store"
import { getQueryVariable } from "@/tool"
import initCaptcha from "@/service/captcha"
import { post } from "@/service"
import { Toast } from "antd-mobile"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { dispatch } = useConfigStore()
  const inviter = getQueryVariable("user")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!!inviter) {
      dispatch({key: "inviter", value: inviter})
      sessionStorage.setItem("inviter", inviter)
    }
  // eslint-disable-next-line
  }, [inviter])

  useEffect(() => {
    sessionStorage.removeItem("captcha")
    sessionStorage.setItem("status", "")
    sessionStorage.setItem("loginStep", "")
    sessionStorage.setItem("code", "")
    dispatch({key: "status", value: ""})
    dispatch({key: "loginStep", value: ""})
    dispatch({key: "code", value: ""})
  // eslint-disable-next-line
  }, [])

  async function continueAction(value) {
    try {
      setLoading(true)
      const resp = await post("/app/account/checkAccount/email", {
        "email": value
      })
      setLoading(false)
      sessionStorage.setItem("email", value)
      dispatch({key: "email", value: value})
      if (!!resp.exitCashUser) {
        sessionStorage.setItem("status", "login")
        dispatch({key: "status", value: "login"})
        history.push("/setup")
      } else {
        initCaptcha(value, () => {
          sessionStorage.setItem("status", "register")
          dispatch({key: "status", value: "register"})
          history.push("/verify")
        })
      }
    } catch (error) {
      setLoading(false)
      if (error === "Email already exist") {
        sessionStorage.setItem("status", "login")
        dispatch({key: "status", value: "login"})
        history.push("/setup")
      } else {
        Toast.show({
          icon: 'fail',
          content: error,
        })
      }
    }
  }
  
  return (
    <div className="home-page">
      <div className="banner">
        <img src={IMAGE.logo} alt="" />
        <h2>{ t("home.title", {name: inviter}) }</h2>
      </div>
      <Kform1
        loading={loading}
        pushFunc={(value) => continueAction(value)}
      />
    </div>
  )
}
export default withRouter(IndexPage)
import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Button, Input, SpinLoading, Toast } from "antd-mobile"
import { useConfigStore } from "@/store"
import initCaptcha from "@/service/captcha"
import { post } from "@/service"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { state } = useConfigStore()
  const { email, status } = state
  const { loading, pushFunc } = props
  const timeRef = useRef()
  const inputRef = useRef(null)
  const [value, setValue] = useState("")
  const [code, setCode] = useState("")
  const [countdown, setCountdown] = useState(0)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setValue(email)
  }, [email])

  async function sendFunc() {
    initCaptcha(email, () => {
      setReload(!reload)
    })
  }

  useEffect(() => {
    async function send() {
      try {
        setSendLoading(true)
        const _captcha = sessionStorage.getItem("captcha")
        const __captcha = JSON.parse(_captcha)
        const { type, data } = __captcha
        const tag = status === "register" ? "SIGNUP" : "RESET_PASSWORD"
        const params = type === "TENCENT" ? {
          captchaType: "TENCENT",
          email: email,
          tencentRandstr: data.randstr,
          tencentTicket: data.ticket,
          tag: tag
        } : {
          captchaType: "RECAPTCHA",
          email: email,
          googleResponse: data,
          tag: tag
        }
        await post("/app/account/sendEmailVerifyCodeV4", params)
        setSendLoading(false)
        setCountdown(60)
        Toast.show({
          icon: 'success',
          content: t("success")
        })
      } catch (error) {
        setSendLoading(false)
        Toast.show({
          icon: 'fail',
          content: error,
        })
      }
    }
    send() 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  useEffect(() => {
    if (countdown > 0) {
      timeRef.current = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } 
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [countdown])

  return (
    <div className="form2-component">
      <h1 className="title">{ t(`form2.email.title`) }</h1>
      <h2 className="content">{ t(`form2.email.content`, { value: value }) }</h2>
      <h2 className="t1">{ t("form2.code") }</h2>
      <div className="input-container">
        <Input 
          ref={inputRef} 
          value={code} 
          onFocus={() => setKeyboardVisible(true)}
          onBlur={() => setKeyboardVisible(false)}
          onChange={value => {
            const regex = /^\d+$/
            if (regex.test(value) || value === '') {
              if (value.length > 6) return
              setCode(value)
            }
          }} 
        />
        <div className="code-list" onClick={(event) => {
          event.stopPropagation()
          inputRef.current.focus()
        }}>
        {
          Array.from({ length: 6 }).map((_, index) => {
            return <div className="code-item" key={index} style={{ borderStyle: keyboardVisible && code.length === index ? "solid" : "none" }}>
              <h1 className="code">{ code.length > index ? code[index] : '' }</h1>
            </div>
          })
        }
        </div>
      </div>
      {
        countdown > 0 ? 
        <h2 className="t2" style={{ color: '#FFBB80' }}>{ t("form2.resended", { countdown: countdown }) }</h2> : 
        sendLoading ?
        <SpinLoading style={{ '--size': '16px', '--color': '#FF7700', margin: '16px 0' }} /> :
        <h2 className="t2" style={{ cursor: 'pointer' }} onClick={sendFunc}>{ t("form2.resend") }</h2>
      }
      <Button loading={loading} disabled={code.length !== 6} className="button" onClick={() => pushFunc(code)}>{ t("continue") }</Button>
    </div>
  )
}

export default IndexComponent
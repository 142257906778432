import React from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import { useConfigStore } from "@/store"
import { Button } from "antd-mobile"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { state } = useConfigStore()
  const { status } = state

  return (
    <div className="success-page">
      <img src={IMAGE.success} alt="" />
      <h1 className="title">{ t(`success.${status}.title`) }</h1>
      <h2 className="content">{ t("success.content") }</h2>
      {/* <Button className="futures-pay-button">{ t("success.button.futures.pay") }</Button> */}
      <Button className="futures-cash-button" onClick={() => history.push("/download")}>{ t("success.button.futures.cash") }</Button>
    </div>
  )
}
export default withRouter(IndexPage)
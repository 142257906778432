// 判断是否是邮箱
export function isEmail(email) {
  var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailPattern.test(email)
}

export const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["name"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}

export function getQueryVariable(variable) {
  var url = new URL(window.location.href)
  var params = new URLSearchParams(url.search)
  var paramValue = params.get(variable)
  return paramValue
}
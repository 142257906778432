import React from "react"
import { IMAGE } from "@/assets"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexComponent = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="foot-component">
      <div className="d1" onClick={() => {
        if (i18n.language.startsWith("en")) {
          i18n.changeLanguage("zh")
        } else {
          i18n.changeLanguage("en")
        }
      }}>
        <img src={IMAGE.language} alt="" />
        <h1>{ t('language') }</h1>
      </div>
    </div>
  )
}

export default IndexComponent
import React, { useState, useEffect, useRef } from "react"
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Input, Button } from "antd-mobile"
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons"
import { isEmail } from "@/tool"
import { useConfigStore } from "@/store"
import "./styles.less"

const IndexComponent = (props) => {
  const { t } = useTranslation()
  const { state } = useConfigStore()
  const { inviter, email, status, loginStep } = state
  const { loading, pushFunc, forgetPasswordFunc } = props
  const inputRef = useRef(null)
  const location = useLocation()
  const { pathname } = location
  const isHome = pathname === "/"
  const type = pathname === "/" ? "email" : status === 'login' && loginStep !== 'forget' ? 'login' : "password"
  const [value, setValue] = useState("")
  const [focus, setFocus] = useState(false)
  const isInvalid = !!value && (isHome ? !isEmail(value) : false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    inputRef.current.focus()
    isHome && setValue(email)
  }, [email, isHome])

  return (
    <div className="form1-component">
      <h1 className="title">{ t(`form1.${type}.title`, {name: inviter}) }</h1>
      {
        type !== 'login' && <h2 className="content">{ t(`form1.${type}.content`) }</h2>
      }
      <h2 className="type">{ t(`form1.${type}.type`) }</h2>
      <div className="input-div" style={{ 
        backgroundColor: isHome && isInvalid ? "#FFF6F6" : "#F7F7F7",
        borderColor: isHome && isInvalid ? "#FF5151" : focus ? "#212121" : "#FFF6F6"
      }}>
        <Input 
          ref={inputRef} 
          type={!visible && !isHome ? "password" : "text"}
          clearable 
          placeholder={t(`form1.${type}.type`)}  
          value={value}
          className="input"
          onChange={value => setValue(value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {
          !isHome ? visible ? <EyeInvisibleOutline className="eye-button" onClick={() => setVisible(false)} /> : <EyeOutline className="eye-button" onClick={() => setVisible(true)} /> : <></>
        }
      </div>
      {
        isHome && isInvalid && <h2 className="txt">{ t("form1.email.txt") }</h2>
      }
      {
        status === 'login' && loginStep !== 'forget'  && <h2 className="forget-button" onClick={() => forgetPasswordFunc()}>{ t("form1.login.forget") }</h2>
      }
      <Button disabled={!value || isInvalid} loading={loading} className="button" onClick={() => pushFunc(value)}>{ t("continue") }</Button>
    </div>
  )
}

export default IndexComponent
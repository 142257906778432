import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Knavbar, Kform2 } from "@/component"
import { Toast } from "antd-mobile"
import { post } from "@/service"
import { useConfigStore } from "@/store"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { dispatch, state } = useConfigStore()
  const { email, status } = state
  const [loading, setLoading] = useState(false)

  async function continueAction(code) {
    if (status === "register") {
      try {
        setLoading(true)
        await post("/app/account/emailVerify", {
          "code": code,
          "email": email,
          "tag": "SIGNUP"
        })
        setLoading(false)
        sessionStorage.setItem("code", code)
        dispatch({key: "code", value: code})
        history.push("/setup")
      } catch (error) {
        setLoading(false)
        Toast.show({
          icon: 'fail',
          content: error,
        })
      }
    } else {
      sessionStorage.setItem("code", code)
      dispatch({key: "code", value: code})
      history.push("/setup")
    }
  }
  
  return (
    <div className="verify-page">
      <Knavbar />
      <Kform2
        loading={loading}
        pushFunc={continueAction}
      />
    </div>
  )
}
export default withRouter(IndexPage)
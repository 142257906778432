import { post } from "@/service"
import { Toast } from "antd-mobile"

const initTencentCaptcha = (callBack) => {
  return new window.TencentCaptcha("2038742797", (res) => {
    const resp = {
      type: "TENCENT",
      data: res
    }
    sessionStorage.setItem("captcha", JSON.stringify(resp))
    callBack(resp)
  }, {})
}

const initGrecaptcha = (callBack) => {
  window.grecaptcha.ready(async function() {
    const token = await window.grecaptcha.execute("6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4", {action: "submit"})
    const resp = {
      type: "RECAPTCHA",
      data: token
    }
    sessionStorage.setItem("captcha", JSON.stringify(resp))
    callBack(resp)
  })
}

const initCaptcha = async (email, callBack) => {
  try {
    const resp = await post("/app/captcha/needCaptcha", {
      "email": email, 
      "mobile": "", 
      "countryCode": "", 
      "username": ""
    })
    const { type } = resp
    if (type === "TENCENT") {
      initTencentCaptcha(callBack).show()
    } else if (type === "RECAPTCHA") {
      initGrecaptcha(callBack)
    }
  } catch (error) {
    Toast.show({
      icon: 'fail',
      content: error.message.toString(),
    })
  }
}

export default initCaptcha
const reducer = (state, action) => {
  switch(action.key) {
    case "inviter":
      return { ...state, inviter: action.value }
    case "email":
      return { ...state, email: action.value }  
    case "code":
      return { ...state, code: action.value }  
    case "status":
      return { ...state, status: action.value } 
    case "loginStep":
      return { ...state, loginStep: action.value } 
    default:
      return state
  }
}

export default reducer
import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Knavbar, Kform1 } from "@/component"
import { useConfigStore } from "@/store"
import { useTranslation } from "react-i18next"
import { Toast } from "antd-mobile"
import initCaptcha from "@/service/captcha"
import { post } from "@/service"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { dispatch, state } = useConfigStore()
  const { email, code, status, loginStep } = state
  const [loading, setLoading] = useState(false)

  function continueAction(password) {
    if (status === "register") {
      if (password.length < 8) {
        Toast.show({
          icon: 'fail',
          content: t("form1.password.t1"),
        })
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/.test(password)) {
        Toast.show({
          icon: 'fail',
          content: t("form1.password.t2"),
        })
      } else {
        initCaptcha(email, () => {
          register(password)
        })
      }
    } else if (loginStep !== 'forget') {
      initCaptcha(email, () => {
        login(password)
      })
    } else {
      if (password.length < 8) {
        Toast.show({
          icon: 'fail',
          content: t("form1.password.t1"),
        })
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/.test(password)) {
        Toast.show({
          icon: 'fail',
          content: t("form1.password.t2"),
        })
      } else {
        setPassword(password)
      }
    }
  }

  async function register(password) {
    try {
      setLoading(true)
      const _captcha = sessionStorage.getItem("captcha")
      const __captcha = JSON.parse(_captcha)
      const { type, data } = __captcha
      const params = type === "TENCENT" ? {
        captchaType: "TENCENT",
        email: email,
        tencentRandstr: data.randstr,
        tencentTicket: data.ticket,
        verify_code: code,
        password: password,
        invite_code: "",
        verify_code_tag: "SIGNUP"
      } : {
        captchaType: "RECAPTCHA",
        email: email,
        googleResponse: data,
        verify_code: code,
        password: password,
        invite_code: "",
        verify_code_tag: "SIGNUP"
      }
      await post("/app/account/signUp/email/v2", params)
      setLoading(false)
      Toast.show({
        icon: 'success',
        content: t("success")
      })
      history.push("/success")
    } catch (error) {
      setLoading(false)
      Toast.show({
        icon: 'fail',
        content: error,
      })
    }
  }

  async function login(password) {
    try {
      setLoading(true)
      const _captcha = sessionStorage.getItem("captcha")
      const __captcha = JSON.parse(_captcha)
      const { type, data } = __captcha
      const params = type === "TENCENT" ? {
        captchaType: "TENCENT",
        type: "EMAIL",
        accountStr: email,
        tencentRandstr: data.randstr,
        tencentTicket: data.ticket,
        password: password,
      } : {
        captchaType: "RECAPTCHA",
        type: "EMAIL",
        accountStr: email,
        googleResponse: data,
        password: password,
      }
      await post("/app/account/loginV5", params)
      setLoading(false)
      Toast.show({
        icon: 'success',
        content: t("success")
      })
      history.push("/success")
    } catch (error) {
      setLoading(false)
      Toast.show({
        icon: 'fail',
        content: error,
      })
    }
  }

  async function setPassword(password) {
    try {
      setLoading(true)
      await post("/app/account/setPassword/unlogin/v2", {
        countryCode: "",
        mobile: "",
        email: email,
        code: code,
        password: password,
        tag: "RESET_PASSWORD"
      })
      initCaptcha(email, () => {
        login(password)
      })
    } catch (error) {
      setLoading(false)
      Toast.show({
        icon: 'fail',
        content: error,
      })
    }
  }

  function forgetPassword() {
    initCaptcha(email, () => {
      sessionStorage.setItem("loginStep", "forget")
      dispatch({key: "loginStep", value: "forget"})
      history.replace("/verify")
    })
  }
  
  return (
    <div className="setup-page">
      <Knavbar />
      <Kform1 
        loading={loading}
        pushFunc={continueAction}
        forgetPasswordFunc={forgetPassword}
      />
    </div>
  )
}
export default withRouter(IndexPage)
import logo from "./assets/logo.png"
import language from "./assets/language.png"
import back from "./assets/back.png"
import arrow from "./assets/arrow.png"
import checked from "./assets/checked.png"
import success from "./assets/success.png"
import appStore from "./assets/appStore.png"
import googlePlay from "./assets/googlePlay.png"

const IMAGE = {
  logo, language, back, arrow, checked, success, appStore, googlePlay
}

export default IMAGE
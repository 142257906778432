import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@/i18n"
import { Home, Verify, Setup, Success, Download, NotFound } from "@/page"
import { Kfoot } from "@/component"
import "./App.less"

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/verify"><Verify /></Route>
        <Route path="/setup"><Setup /></Route>
        <Route path="/success"><Success /></Route>
        <Route path="/download"><Download /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
      <Kfoot />
    </Router>
  )
}

export default App